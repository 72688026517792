<div rvSpinner="powerbi-loader" [rvShowSpinner]="spinner">
  <div class="attribute-editor-component rise-powerbi-container te-scrollable-container">

    <div class="attribute-editor-row" [shown]="revokeFailed">
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <div class="text-sm">
              We could not revoke your access with Power BI at this time. We strongly recommend you manually revoke access in your Microsoft account.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="attribute-editor-row">
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group" [ngClass]="{ 'has-error': authenticateFailed || componentDisconnected }">
              @if(state === states.NotConfigured) {
                <div class="mb-4">
                  Connect to Power BI to access your dashboards or reports.
                </div>
                <button class="btn btn-provider-auth btn-microsoft-auth dark btn-hg btn-block mt-2" (click)="confirmConnect()">
                  <img src="https://upload.wikimedia.org/wikipedia/commons/2/25/Microsoft_icon.svg">
                  <div class="mx-auto">Sign in with Microsoft</div>
                </button>
              }
              @else {
                <label>Account:</label><br>
                @if(componentDisconnected) {
                  <p class="help-block">
                    <span>The Power BI user disconnected.</span>
                  </p>
                }
                @else {
                  Power BI is connected to {{componentAccountUsername}}<br>
                }

                @if(state === states.ConfiguredByMe) {
                  <button class="btn btn-provider-auth btn-microsoft-auth light btn-hg btn-block mt-2" (click)="confirmDisconnect()">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/2/25/Microsoft_icon.svg">
                    <div class="mx-auto">Disconnect from Microsoft</div>
                  </button>
                }
                @else {
                  <button class="btn btn-default btn-block mt-3" (click)="confirmChange()">Change Power BI Account</button>
                }
              }
              <p class="help-block" [shown]="authenticateFailed">
                <span>Sorry, it seems there's an issue with authentication.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="attribute-editor-row" [shown]="state !== states.NotConfigured">
      <div class="row">
        <div class="col-xs-12">
          <hr class="flex-grow border-top">
        </div>
      </div>
    </div>

    <div class="attribute-editor-row" [shown]="state === states.ConfiguredByMe">
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <mat-button-toggle-group [(ngModel)]="contentType" (ngModelChange)="contentTypeChanged()">
              <mat-button-toggle [value]="ContentType.REPORT">Reports</mat-button-toggle>
              <mat-button-toggle [value]="ContentType.DASHBOARD">Dashboards</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>

    <div class="attribute-editor-row" [shown]="state !== states.NotConfigured">
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group" [ngClass]="{ 'has-error': groupsFailed }">
            <label>Workspace:</label><br>
            @if(state === states.ConfiguredByMe) {
              <select name="te-powerbi-group-id" class="form-control" [(ngModel)]="selectedGroup" (ngModelChange)="selectedGroupChanged()">
                <option value="" [shown]="false">Select Workspace</option>
                <option value="my-workspace">My Workspace</option>
                @for (g of groups; track g) {
                  <option [value]="g.id">{{g.name}}</option>
                }
              </select>
              @if (groupsFailed) {
                <p class="help-block">
                  <span>Sorry, it seems like we can't provide the list of your workspaces. If the issue persists, contact support.</span>
                </p>
              }
            }
            @else {
              {{selectedGroupName ? selectedGroupName : "none"}}
            }
          </div>
        </div>
      </div>
    </div>

    <div class="attribute-editor-row" [shown]="state !== states.NotConfigured && contentType === ContentType.DASHBOARD">
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group" [ngClass]="{ 'has-error': dashboardsFailed }">
            <label>Dashboard:</label><br>
            @if(state === states.ConfiguredByMe) {
              <select name="te-powerbi-dashboard-id" class="form-control" [(ngModel)]="selectedDashboard" (ngModelChange)="selectedDashboardChanged()">
                <option value="" [shown]="false">Select Dashboard</option>
                @for (d of dashboards; track d) {
                  <option [value]="d.id">{{d.displayName}}</option>
                }
              </select>
              @if (dashboardsFailed) {
                <p class="help-block">
                  <span>Sorry, it seems like we can't provide the list of your dashboards. If the issue persists, contact support.</span>
                </p>
              }
            }
            @else {
              {{selectedDashboardName ? selectedDashboardName : "none"}}
            }
          </div>
        </div>
      </div>
    </div>

    <div class="attribute-editor-row" [shown]="state !== states.NotConfigured && contentType === ContentType.REPORT">
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group" [ngClass]="{ 'has-error': reportsFailed }">
            <label>Reports:</label><br>
            @if(state === states.ConfiguredByMe) {
              <select name="te-powerbi-report-id" class="form-control" [(ngModel)]="selectedReport" (ngModelChange)="selectedReportChanged()">
                <option value="" [shown]="false">Select Report</option>
                @for (r of reports; track r) {
                  <option [value]="r.id">{{r.displayName}}</option>
                }
              </select>
              @if (reportsFailed) {
                <p class="help-block">
                  <span>Sorry, it seems like we can't provide the list of your reports. If the issue persists, contact support.</span>
                </p>
              }
            }
            @else {
              {{selectedReportName ? selectedReportName : "none"}}
            }
          </div>
        </div>
      </div>
    </div>

    <div class="attribute-editor-row" [shown]="state !== states.NotConfigured">
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <div class="flex-row">
              <label class="mt-1">Refresh Rate:</label>
              <streamline-icon matTooltip="Power BI only supports automatic refresh for reports. You can refresh your data of your dashboard by choosing an interval below."
                        matTooltipPosition="right"
                        matTooltipClass="font-weight-bold"
                        ariaLabel="More information"
                        runZone
                        name="info"
                        width="14" height="14"
                        class="aligner rise-blue pl-2"></streamline-icon>
            </div>
            <select name="te-powerbi-dashboard-refresh" class="form-control" [(ngModel)]="refresh" (ngModelChange)="refreshChanged()">
              <option value="0">Never Refresh</option>
              <option value="5">5 minutes</option>
              <option value="30">30 minutes</option>
              <option value="60">60 minutes</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
